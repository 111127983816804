import React, { useState } from 'react';
import TobBar from './TobBar';
import { db } from '../firebase';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import emailjs from 'emailjs-com'
// import './EmailCapturePage.css'; // Import a separate CSS file for additional custom styling

const BookSend = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    const sendEmail = (e) => {
        try {
            const response = fetch('http://localhost:9000/api/send-email', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email: email
              })
            });
            // console.log("handlePaymentClick: Received response from server");
            if (!response.ok) {
              const responseText = response.text();
            //   console.error("handlePaymentClick: Network response was not ok", responseText);
              throw new Error(`Network response was not ok: ${responseText}`);
            }
            const data =  response.json();
            console.log("handlePaymentClick: Data received from server", data);
            // navigate(`/book/${bookId}/checkout/learn`);
            window.location.href = data.checkout_url;
            // console.log("handlePaymentClick: Redirecting to", data.checkout_url);
            // console.log("RETURN URL", data.return_url);
            // console.log("THE DATA!", data);
          }
          catch (error) {
            // console.error('handlePaymentClick: Error occurred during payment process:', error);
          }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your email handling logic here
        setMessage('Thank you! The e-book will be sent to your email.');

        if (email === "" || !/\S+@\S+\.\S+/.test(email)) {
            setIsEmailValid(false);
        } else {
            setIsEmailValid(true);
            //add to firebase
            const docRef = addDoc(collection(db, "toReceiveBooks"), {
                email: email,
                createdAt: serverTimestamp()
            });
            // alert(`Thank you for subscribing with ${email}`);
            setEmail("");
            sendEmail();
        }
    };

    return (
        <>
            <TobBar />
            <div className="book-page flex items-center justify-center mt-40">
                <div className="max-w-md w-full space-y-12 p-10 bg-gray-800 rounded-xl shadow-lg z-10">
                    <div className="text-center">
                        <h2 className="comic-sans-medium mt-3 text-3xl font-extrabold text-yellow-100">Get Your Free E-Book</h2>
                        <p className="comic-sans-tiny mt-5 text-sm text-yellow-100">
                            Enter your email address to receive our amazing e-book for kids!
                        </p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="comic-sans-tiny appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="comic-sans-tiny group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-black bg-yellow-100 hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Send me the E-book
                            </button>
                        </div>
                    </form>
                    {message && <div className="mt-4 text-center text-green-200">{message}</div>}
                </div>
            </div>
        </>
    );
};

export default BookSend;