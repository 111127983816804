import React, { Component, useState, useEffect } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import book from "../assets/images/CoverNew.jpg";
import content from "../assets/images/content.png";
import page1 from "../assets/images/page 1. Hello.png";
import page2 from "../assets/images/page 2.png";
import page3 from "../assets/images/Page 3.png";
import logoImage from "../assets/images/logoAndText.png";
import profile from "../assets/images/Filagot pic.jpg";
import profile2 from "../assets/images/instructor2.jpeg";
import Chapters from "./Chapters";
import Header from "./Header";
import Profile from "./Profile";
import chaptersData from "../chapters.json";

import { setSignInOpen } from "../redux/slices/authDialogSlice";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db } from "../firebase";
import { setUser } from "../redux/slices/userSlice";
import { useUserAuth } from "./Auth/UserAuthContext";
import Modal from "./Modal";
import Pay from "./Payment/Pay";
import PurchaseButton from "./Payment/PurchaseButton";
import ChapterSummaryCard from "./ChapterSummaryCard";
import TobBar from "./TobBar";
import { TERipple } from "tw-elements-react";

const Book = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookId } = useParams();
  console.log("id", bookId);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [stroke, setStroke] = useState(4.8);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  // const tx_ref = `${lName}-tx-30042024`;
  // const public_key = "CHAPUBK_TEST-HjLbBwcTBVXRtY1dsE8gfe5OO3FwiOHo";
  const [user, loading, error] = useAuthState(auth);

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      console.log("DOCS", doc);
      const data = doc.docs[0].data();
      console.log("DATA", data);
      dispatch(setUser(data));
      setFirstName(data.firstname);
      setLastName(data.lastname);
      setPhoneNumber(data.phone_number);
      setEmail(data.email);
      // setName(data.firstname);
      console.log("NAME:", data.firstname);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     setUID(user.uid);
    //     console.log("uid", uid);
    //   } else {
    //     console.log("user is logged out");
    //   }
    // });
    fetchUserName();
  }, [user]);

  const handlePurchase = () => {
    console.log("user is", user ? user?.email : null);
    if (user) {
      navigate(`/book/${bookId}/checkout`);
    } else {
      dispatch(setSignInOpen());
    }
  };

  const handlePaymentClick = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://localhost:9000/api/pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          first_name: first_name,
          last_name: last_name,
          phone_number: phone_number,
          bookId: bookId
        })
      });
      console.log("handlePaymentClick: Received response from server");
      if (!response.ok) {
        const responseText = await response.text();
        console.error("handlePaymentClick: Network response was not ok", responseText);
        throw new Error(`Network response was not ok: ${responseText}`);
      }
      const data = await response.json();
      console.log("handlePaymentClick: Data received from server", data);
      // navigate(`/book/${bookId}/checkout/learn`);
      window.location.href = data.checkout_url;
      console.log("handlePaymentClick: Redirecting to", data.checkout_url);
      console.log("RETURN URL", data.return_url);
      // console.log("THE DATA!", data);
    }
    catch (error) {
      console.error('handlePaymentClick: Error occurred during payment process:', error);
      setLoading(false);
    }

  };

  const handleStartCourses = () => {
    navigate(`/course/${bookId}`);
  };
  return (
    <>
      <TobBar />
      <div className="book-page mt-10">
        <div className="container mx-auto p-4">
          <div className="book-card">
            <div className="book-image">
              <img src={book} alt="Book Cover" className="book-cover mt-10 flex justify-center items-center" />
            </div>
            <div className="book-details bg-gray-700 shadow-lg rounded-lg w-full md:w-1/2 mt-8 p-8 md:p-5 space-y-3">
              <h2 className="book-title text-yellow-100 comic-sans-medium">Business For Kids</h2>
              <p className="book-level text-yellow-100 comic-sans-small border-t-2 p-2">Level: Beginner</p>
              <p className="book-price text-yellow-100 comic-sans-small border-t-2 p-2">Price: 300 ETB </p>
              <p className="book-level text-yellow-100 comic-sans-small border-t-2 p-2">With only $10 - you can send this e-book to your loved ones. <br />
                <TERipple rippleColor="light">
                  <button
                    onClick={handlePaymentClick}
                    type="button"
                    className="mt-5 inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-yellow-100 transition duration-150 ease-in-out hover:bg-yellow-100 hover:text-black focus:bg-yellow-100 focus:text-black focus:outline-none focus:ring-0 active:text-black-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  >
                    Send now
                  </button>
                </TERipple>
              </p>
              <p className="book-description text-yellow-100 comic-sans-tiny border-t-2 p-2">
                Unlock the exciting world of entrepreneurship for your child with "Business for Kids"! This engaging and interactive eBook is designed to introduce young minds to the basics of business and entrepreneurship. Tailored for children aged 7-14, it simplifies complex concepts into fun and understandable lessons.            </p>
              <button
                onClick={handlePaymentClick}
                className="comic-sans-small btn outline-button text-black font-semibold hover:text-black py-2 px-4 rounded"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Purchase Book'}
              </button>
            </div>
          </div>

          <div className="sample-images-section mt-8 space-y-10">
            <h3 className="section-title text-center comic-sans-small text-yellow-100 bg-black p-3">Sample Pages</h3>
            <div className="sample-images">
              <img src={page1} alt="Sample Page 1" className="sample-image shadow-lg rounded-lg" />
              <img src={page2} alt="Sample Page 2" className="sample-image shadow-lg rounded-lg" />
              <img src={page3} alt="Sample Page 3" className="sample-image shadow-lg rounded-lg" />
            </div>
          </div>

          <div className="book-description-and-contents mt-8 bg-white shadow-lg rounded-lg">
            <div className="book-description-section p-3 md:p-8">
              <h3 className="section-title comic-sans-small">Book Description</h3>
              <h4 className="description-list comic-sans-tiny">
                {/* Unlock the exciting world of entrepreneurship for your child with "Business for Kids"! This engaging and interactive eBook is designed to introduce young minds to the basics of business and entrepreneurship. Tailored for children aged 7-14, it simplifies complex concepts into fun and understandable lessons. */}
                {/* <br /> */}
                Inside this eBook, your child will discover:
              </h4>
              <ul className="description-list">
                <li className="comic-sans-tiny">What is Entrepreneurship? Learn about the exciting journey of turning ideas into reality.</li>
                <li className="comic-sans-tiny">Real-World Business Opportunities:  Explore various business ideas that kids can start.</li>
                <li className="comic-sans-tiny">Job vs. Entrepreneurship: Understand the key differences between being an employee and being an entrepreneur.</li>
                <li className="comic-sans-tiny">How to Make a Business Plan: Step-by-step guidance on creating a solid business plan.</li>
                <li className="comic-sans-tiny">Market Research: Learn how to investigate and understand the market for their product.</li>
                <li className="comic-sans-tiny">Pricing Their Amazing Product: Discover strategies for setting the right price for their goods or services.</li>
                <li className="comic-sans-tiny">Marketing:  Find out how to spread the word about their business to a wider audience.</li>
                <li className="comic-sans-tiny">Engaging Activities: Enjoy fun activities at the end of each chapter to reinforce learning.</li>
              </ul>
              <h4 className="description-list comic-sans-tiny">Packed with activities, quizzes, and real-life scenarios, "Business for Kids" makes learning about business fun and engaging. Equip your child with the skills and knowledge they need to think like an entrepreneur and start their own business journey.</h4>
            </div>
            <div className="book-contents p-3 md:p-8">
              <h3 className="section-title comic-sans-small">Contents of the Book</h3>
              <div className="flex justify-center items-center contents-image-container">
                <img src={content} alt="Contents of the Book" className="contents-image" />
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <div className="container flex flex-col items-center mx-auto w-full space-y-10 py-10 lg:py-20 xl:py-28">
        <div className="m-4 sm:flex sm:flex-col sm:items-center">
          <div className="flex flex-col items-center md:flex-row justify-center mt-20 md:mt-5 space-y-10 md:space-y-0 md:space-x-10">
            <img
              className="w-[100%] md:w-[60%] lg:w-[25%] mb-4 sm:mb-0 rounded-2xl"
              // className="rounded-2xl shadow-4-strong w-full md:w-1/2 h-70"
              src={book}
              alt="bookimage"
            />


            <ChapterSummaryCard /> */}


      {/* <div className="sm:w-[40%] flex flex-col items-start">
            <h1 className="comic-sans-large font-bold text-yellow-100 mb-4">
              Business for Kids
            </h1>
            <div className="sm:photo-wrapper p-2 flex items-center border-t-2 w-full mb-4">
              <div className="flex items-center">
                <img
                  className="w-16 h-16 rounded-full mx-auto object-cover"
                  src={profile}
                  alt="John Doe"
                />
                <div className="sm:p-4">
                  <h3 className="text-yellow-100 text-center sm:text-left text-xl text-gray leading-8">
                    Filagot Tesfaye
                  </h3>
                  <p className="text-yellow-100 text-center sm:text-left text-xs sm:text-sm">
                    Author
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start p-4 mb-4">
              <h1 className="font-bold text-xl mr-2">DIGITAL</h1>
              <p className="font-bold text-xl">50 ETB</p>
            </div>
            <p className="comic-sans-small text-lg">
              Discover a world of endless possibilities with our engaging and educational business ebook for kids! Packed with interactive lessons, fun activities, and real-life examples, this ebook is designed to ignite young imaginations and cultivate entrepreneurial skills. Whether your child dreams of launching their own business or simply wants to learn more about the exciting world of entrepreneurship, our ebook is the perfect companion for their journey.
            </p>
            <div className="p-4 item-center">
                <button
              type="submit"
                onClick={handlePaymentClick}
                className="btn outline-button hover:bg-middlesection text-black font-semibold hover:text-black py-2 px-4 rounded"
              >
                Purchase Course
              </button> */}
      {/* </form> */}
      {/* <Pay fName={fName} lName={lName} email={email} tx_ref={tx_ref} public_key={public_key} /> */}
      {/* </div> */}
      {/* </div> */}

      {/* </div>
        </div> */}

      {/* <div className="mt-4 sm:flex flex-row justify-between border-t-2 p-8">
        <div className="sm:w-[40%]">
          <h1 className="text-lg font-bold">What you'll learn</h1>
          <ul className="mt-2 list-disc list-inside">
            <li>
              Gain an immersive understanding of the practices and processes used
              by a junior or associate data analyst in their day-to-day job
            </li>
            <li>
              Understand how to clean and organize data for analysis, and complete
              analysis and calculations using spreadsheets, SQL and R programming
            </li>
            <li>
              Learn key analytical skills (data cleaning, analysis, & visualization)
              and tools (spreadsheets, SQL, R programming, Tableau)
            </li>
            <li>
              Learn how to visualize and present data findings in dashboards,
              presentations and commonly used visualization platforms
            </li>
          </ul>
        </div>
        <div id="sample" className="w-[50%]">
          <video className="h-full w-full rounded-lg" controls>
            <source
              src="https://docs.material-tailwind.com/demo.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div> */}

      {/* <div
          id="courses"
          className="flex flex-col sm:flex-row justify-between border-t-2 p-8"
        >
            <div className="bg-white border-2 p-4 rounded-lg w-full md:w-1/2 h-[100] shadow-4-strong flex flex-col justify-center items-center sm:items-center p-4">
            <div id="sample" className="flex flex-row sm:w-[100%] mt-2">
              <h2 className="comic-sans-tiny text-sm">Unlock the exciting world of entrepreneurship for your child with "Business for Kids"! <br />This engaging and interactive eBook is designed to introduce young minds to the basics of business and entrepreneurship. Tailored for children aged 7-14, it simplifies complex concepts into fun and understandable lessons.
<br /><br />
                Inside this eBook, your child will discover: <br />
                •	What is Entrepreneurship? Learn about the exciting journey of turning ideas into reality.<br />
                •	Real-World Business Opportunities:  Explore various business ideas that kids can start.<br />
                •	Job vs. Entrepreneurship: Understand the key differences between being an employee and<br /> being an entrepreneur.<br />
                •	How to Make a Business Plan: Step-by-step guidance on creating a solid business plan.<br />
                •	Market Research: Learn how to investigate and understand the market for their product.<br />
                •	Pricing Their Amazing Product: Discover strategies for setting the right price for their goods or services.<br />
                •	Marketing:  Find out how to spread the word about their business to a wider audience.<br />
                •	Engaging Activities: Enjoy fun activities at the end of each chapter to reinforce learning.<br />
                <br />
                Packed with activities, quizzes, and real-life scenarios, "Business for Kids" makes learning about business fun and engaging. Equip your child with the skills and knowledge they need to think like an entrepreneur and start their own business journey.</h2> */}
      {/* <Profile /> */}
      {/* </div>
            <div className="p-4"> */}


      {/* <button
              onClick={handlePurchase}
              className="btn outline-button hover:bg-middlesection text-black font-semibold hover:text-black py-2 px-4 hover:border-black rounded"
            >
              Purchase Course
            </button> */}
      {/* </div>
          </div> */}
      {/* <div className="sm:w-[40%] flex flex-col items-center sm:items-start">
          <Chapters id={bookId} chaptersData={chaptersData} />
        </div> */}

      {/* </div>
      </div> */}
    </>
  );
}

export default Book;
